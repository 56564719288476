
    .carousel-el {
    scroll-snap-align: start;
    max-width: 100%;
    min-width:100% ;
  }
  .carousel-el__img {
    max-width: 100%;
  }

